<template>
  <v-card
    dark
    :color="darkTheme ? null : 'pink accent-4'"
    elevation="6"
    class="twitter-card"
  >
    <div class="instagram-card-img-background">
      <div class="img-background-container">
        <div class="overlay-ig-bg"></div>
        <v-img :src="ig_post.media_url"></v-img>
      </div>
    </div>
    <!-- <div class="twitter-card-icon-background">
      <v-icon>mdi-instagram</v-icon>
    </div> -->
    <v-card-title class="pt-4 pb-0 my-0 text-body-1" v-if="!small">
      <span
        :class="small ? 'twitter-card-title-truncate' : 'twitter-card-title '"
      >
        {{ ig_post.nombre }}
      </span>
      <v-spacer v-if="!small"></v-spacer>
      <!-- <v-btn icon @click="confirmDialog = true" v-if="!small">
        <v-icon>mdi-delete</v-icon>
      </v-btn> -->
      <v-btn icon @click="sendClose()" v-if="!small"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-subtitle
      :class="'pa-0 pb-2 my-0 text-caption ' + (small ? 'pt-6' : 'pt-0')"
    >
      <span :class="'twitter-card-subtitle ' + (small ? 'ml-4' : 'ml-6')">
        {{ ig_post.timestamp }}
      </span>
    </v-card-subtitle>

    <v-card-text
      :class="dialog ? 'pt-3' : null"
      :style="small ? 'height: 85px' : null"
    >
      <span
        :class="
          small ? 'twitter-card-text-truncate pb-0 mb-0' : 'twitter-card-text'
        "
      >
        {{ ig_post.caption }}
        <v-img :src="ig_post.media_url" v-if="!small"></v-img>
      </span>
      <!-- <br />
      <span :class="'twitter-card-subtitle ml-0 mt-0 pt-0' + (small ? 'pt-0' : 'pt-0')">
        {{ ig_post.created_time }}
      </span> -->
      <!-- <v-row align="center" justify="end" class="pt-4" v-if="comments">
        <v-col cols="10" class="py-0" v-for="(item, index) in comments" :key="index">
          <v-alert
            border="right"
            :color="darkTheme ? null : '#2D77FF'"
            class="text-right myAlert"
          >
            <span class="card-comment"> {{ item.message }} </span><br />
            <span class="card-date">
              {{ item.created_time }}
            </span>
          </v-alert>
        </v-col>
      </v-row> -->
    </v-card-text>

    <v-card-actions class="py-0 my-0">
      <v-list-item>
        <v-row align="center">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="$emit('openDialog')"
            v-if="small"
          >
            <v-icon small class="mr-2">mdi-eye</v-icon>
            Ver
          </v-btn>
          <v-btn color="secondary" @click="confirmDialog = true" v-if="!small">
            <v-icon>mdi-delete</v-icon>Eliminar
          </v-btn>
        </v-row>
      </v-list-item>
    </v-card-actions>

    <v-dialog v-model="confirmDialog" width="400px">
      <v-card>
        <v-card-title class="text-center">
          <h5>¿Seguro que desea eliminar?</h5>
        </v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="secondary" @click="confirmDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            small
            color="primary"
            @click="deletePost()"
            :loading="isLoading"
            :disabled="isLoading"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  props: {
    ig_post: { type: Object, required: true },
    small: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  data() {
    return {
      buttonLoading: false,
      confirmDialog: false,
      postComments: null,
    };
  },
  methods: {
    sendClose() {
      this.$emit("closeDialog", true);
    },
    sendRefresh() {
      this.$emit("closeDialogRefresh");
    },
    deletePost() {
      this.buttonLoading = true;
      this.confirmDialog = false;
      let data = new FormData();

      data.append("id", this.ig_post.id);

      axios
        .post("public/deletepost", data)
        .then((response) => {
          this.buttonLoading = false;
          this.$store.commit("showSnackBar", {
            text:
              typeof response.data === "object" && "message" in response.data
                ? response.data.message
                : response.data,
            color: "success",
            icon: "mdi-check-circle",
          });
          this.sendRefresh();
        })
        .catch(() => {
          // console.log(error.response.data);
          this.buttonLoading = false;
          this.sendClose();
        });
    },
    getComments() {
      let data = new FormData();
      data.append("id", this.ig_post.id);
      axios.post("public/searchcomments", data).then((response) => {
        this.postComments = response.data;
      });
      // .catch((error) => {
      //   console.log(error.response.data);
      // });
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    isLoading() {
      return this.buttonLoading;
    },
    comments() {
      return this.postComments;
    },
  },
  beforeMount() {
    !this.small ? this.getComments() : null;
  },
};
</script>
