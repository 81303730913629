<template>
  <div>
    <v-tabs v-model="instagramTab" align-with-title class="bg-transparent">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab><v-icon class="mr-2">mdi-instagram</v-icon> Publicaciones pendientes</v-tab>
      <v-tab><v-icon class="mr-2">mdi-instagram</v-icon> Mis publicaciones</v-tab>
    </v-tabs>
    <v-tabs-items v-model="instagramTab" class="bg-transparent">
      <v-tab-item>
        <PendientesIG />
      </v-tab-item>
      <v-tab-item>
        <PostsIG />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PendientesIG from "@/components/PendientesIG";
import PostsIG from "@/components/PostsIG";

export default {
  components: {
    PendientesIG,
    PostsIG
  },
  data() {
    return {
      instagramTab: null,
    };
  },
};
</script>