<template>
  <v-card dark :color="darkTheme ? null : 'pink accent-4'" elevation="6" class="twitter-card">
    <div class="twitter-card-icon-background"><v-icon>mdi-instagram</v-icon></div>
    <v-sheet
      :color="darkTheme ? null : 'pink accent-4'"
      class="rounded-circle twitter-avatar-dialog"
    >
      <v-avatar size="65" class="ma-1">
        <v-img alt="Instagram" src="@/assets/images/instagram.png"></v-img>
      </v-avatar>
    </v-sheet>
    <v-card-title class="pt-4 pb-0 my-0 text-body-1">
      <span class="twitter-card-title">
        {{ ig_post.nombre }}
      </span>
      <v-spacer></v-spacer>
      <v-btn icon link :href="ig_post.origen" target="_blank">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn icon @click="sendClose()"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle class="pa-0 pb-2 my-0 ml-6 text-caption card-date">
      <span class="twitter-card-subtitle align-center">
        {{ ig_post.fecha_creacion }}
      </span>
    </v-card-subtitle>

    <v-card-text class="pt-3">
      <span class="twitter-card-text">
        {{ ig_post.mensaje }}
      </span>
    </v-card-text>
    <v-card-actions class="py-0 my-0">
      <v-container fluid>
        <v-form class="mb-4 w-100">
          <v-row justify="end">
            <v-col>
              <v-select
                prepend-icon="mdi-filter-variant"
                :items="status_list"
                v-model="selectedStatus"
                label="Estatus"
                :disabled="blocked"
                clearable
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 mb-0">
              <v-textarea
                filled
                label="Respuesta"
                v-model="ig_post.sugerencia"
                rows="6"
                :disabled="locked"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end pt-0 mt-0">
              <v-btn icon @click="toggleLock()" v-if="!blocked">
                <v-icon>{{ locked ? "mdi-lock" : "mdi-lock-open" }}</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="confirmDialog = true"
                :loading="isLoading"
                :disabled="isLoading || blocked"
                >Responder
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="confirmDialog" width="400px">
      <v-card>
        <v-card-title class="text-center">
          <h5>¿Seguro que desea responder?</h5>
        </v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="secondary" @click="confirmDialog = false">Cancelar</v-btn>
          <v-btn
            small
            color="primary"
            @click="replyComment()"
            :loading="isLoading"
            :disabled="isLoading"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      buttonLoading: false,
      lockedReply: true,
      confirmDialog: false,
      selectedStatus: this.getStatus(5),
      reply: this.ig_post.sugerencia,
    };
  },
  props: {
    ig_post: { type: Object, required: true },
    status_list: { type: Array, required: true },
  },
  methods: {
    sendClose() {
      this.$emit("closeDialog");
    },
    sendRefresh() {
      this.$emit("closeDialogRefresh");
    },
    toggleLock() {
      this.locked = !this.locked;
    },
    getStatus(statusId) {
      for (let i = 0; i < this.status_list.length; i++) {
        if (this.status_list[i].value == statusId) {
          // console.log(this.status_list[i]);
          return this.status_list[i];
        }
      }
      return null;
    },
    replyComment() {
      this.confirmDialog = false;
      this.buttonLoading = true;
      if (this.reply.length == 0) {
        this.$store.commit("showSnackBar", {
          text: "No hay post para enviar",
          color: "warning",
          icon: "mdi-alert",
        });
        this.buttonLoading = false;
        this.sendClose();
        return;
      }

      let selectedStatus =
        typeof this.selectedStatus === "object" && "value" in this.selectedStatus
          ? this.selectedStatus.value
          : this.selectedStatus;
      if (selectedStatus == null || selectedStatus == 1) {
        this.$store.commit("showSnackBar", {
          text: "Selecciona un status",
          color: "warning",
          icon: "mdi-alert",
        });
        this.buttonLoading = false;
        return;
      }
      let data = new FormData();

      data.append("id", this.ig_post.id_dato);
      data.append("respuesta", this.reply);
      data.append("status", selectedStatus);

      axios
        .post("public/igreplycomments", data)
        .then((response) => {
          this.buttonLoading = false;
          this.$store.commit("showSnackBar", {
            text:
              typeof response.data === "object" && "message" in response.data
                ? response.data.message
                : response.data,
            color: "success",
            icon: "mdi-check-circle",
          });
          this.sendRefresh();
        })
        .catch(() => {
          // console.log(error.response.data);
          this.buttonLoading = false;
          this.sendRefresh();
        });
    },
    test() {
      this.confirmDialog = true;
      console.log(this.selectedStatus);
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    isLoading() {
      return this.buttonLoading;
    },
    blocked() {
      return this.ig_post.id_status != 1;
    },
    locked: {
      get: function () {
        return this.lockedReply;
      },
      set: function (locked) {
        this.lockedReply = locked;
      },
    },
  },
};
</script>
